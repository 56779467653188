<template>
  <div class="header" @mousemove="mousemove($event)">
    <div class="videoMask">
      <video ref="video" class="video" preload="auto" loop playsinline autoplay :src="videoArray[videoIndex]"
        tabindex="-1" muted></video>
    </div>
    <router-view></router-view>

    <vueDraggableResizable v-if="isShowDraggableImg" class="draggable" :resizable="true" :w="310" :h="310"
      :draggable="true">
      <img style="display: block;width: 100%;height: 100%;" src="./assets/images/events.png" alt="" />
    </vueDraggableResizable>


    <div class="bottomNav" v-if="isShowBottomNav">
      <div class="navItem" @click="navTab(0)">
        <div class="icon">
          <img class="menuIcon" :class="[activeIndex == 0 ? 'actBgc' : '']" src="./assets/icon/3dqiandao.png" alt="">
          <div>3D签到</div>
        </div>
      </div>
      <div class="navItem" @click="navTab(1)">
        <div class="icon">
          <img class="menuIcon" :class="[activeIndex == 1 ? 'actBgc' : '']" src="./assets/icon/yaodajiang.png" alt="">
          <div>摇大奖</div>
        </div>
      </div>
      <div class="navItem" @click="navTab(2)">
        <div class="icon">
          <img class="menuIcon" :class="[activeIndex == 2 ? 'actBgc' : '']" src="./assets/icon/choujiang.png" alt="">
          <div>抽奖</div>
        </div>
      </div>
      <div class="navItem" @click="navTab(3)">
        <div class="icon">
          <img class="menuIcon" :class="[activeIndex == 3 ? 'actBgc' : '']" src="./assets/icon/xiemu.png" alt="">
          <div>开幕</div>
        </div>
      </div>
      <div class="navItem" @click="navTab(4)">
        <div class="icon">
          <img class="menuIcon" :class="[activeIndex == 4 ? 'actBgc' : '']" src="./assets/icon/kaimu.png" alt="">
          <div>闭幕</div>
        </div>
      </div>
      <div class="line"></div>

      <div class="menu" @click="isShowDanMu = !isShowDanMu">
        <div class="icon">
          <img class="menuIcon" v-if="isShowDanMu" src="./assets/icon/kaiqidanmu.png" alt="">
          <img class="menuIcon" v-else src="./assets/icon/guanbidanmu.png" alt="">
          <div> {{ isShowDanMu ? '关闭弹幕' : '打开弹幕' }}</div>
        </div>
      </div>
      <div class="menu" @click="isShowDraggableImg = !isShowDraggableImg">
        <div class="icon">
          <img class="menuIcon" v-if="isShowDraggableImg" src="./assets/icon/kaiqierweima.png" alt="">
          <img class="menuIcon" v-else src="./assets/icon/gaunbierweima.png" alt="">
          <div> {{ isShowDraggableImg ? "二维码关" : "二维码开" }}</div>
        </div>
      </div>
      <div class="menu" @click="screenfull()">
        <div class="icon">
          <img class="menuIcon" v-if="isFull" src="./assets/icon/quanping.png" alt="">
          <img class="menuIcon" v-else src="./assets/icon/guanbiquanping.png" alt="">
          <div> {{ isFull ? "全屏开" : "全屏关" }}</div>
        </div>

      </div>
      <div class="menu" @click="playMusic()">
        <div class="icon">
          <img class="menuIcon" v-if="isPlayMusic" src="./assets/icon/kaiqishengyin.png" alt="">
          <img class="menuIcon" v-else src="./assets/icon/gaunshengyin.png" alt="">
          <div> 背景音乐</div>
        </div>

      </div>
    </div>


    <vue-danmaku v-if="isShowDanMu" class="danmaku" ref="danmaku" v-model="danmus" :autoplay="true" use-slot loop
      :randomChannel="true" :speeds="200" :channels="40">
      <template slot="dm" slot-scope="{ index, danmu }">
        <div class="danmuBox">
          <div class="avatar">
            <img class="quan" src="./assets/images/quan (1).png" alt="" srcset="">
            <img class="danMuimg" :src="danmus[index].avatar" alt="" srcset="">
          </div>
          <div class="danmuText">
            {{ danmu.name }}:{{ danmus[index].text }}
          </div>
        </div>
      </template>

    </vue-danmaku>

    <div class="lock" v-if="isLock">
      <div class="centerMinBox">
        <div class="text">请输入活动密码</div>
        <div class="inputBox">
          <input type="password" id="input" @keydown.enter="lockey" placeholder="请输入活动密码" v-model="key">
          <!-- <el-button type="success" style="height: 33px;width: 100px;margin-left: 10px;line-height: 15px;" plain
            @click="lockey">确认</el-button> -->
          <div class="lockBtn" @click="lockey" @keydown.enter="lockey">确定</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import vueDanmaku from 'vue-danmaku'
import vueDraggableResizable from 'vue-draggable-resizable'
import 'vue-draggable-resizable/dist/VueDraggableResizable.css'
import screenfull from "screenfull";
export default {
  components: {
    vueDraggableResizable, vueDanmaku
  },
  name: "App",
  data() {
    return {
      videoArray: [
        require("@/assets/video/qiandao.mp4"),
        require("@/assets/video/yaodajiang.mp4"),
        // require("@/assets/video/choudajiang.mp4"),
        require("@/assets/video/choujiang2.mp4"),
        require("@/assets/video/kaimu.mp4"),
        require("@/assets/video/bimu.mp4"),
      ],
      videoIndex: 0,
      clientHeight: null,
      clientWidth: null,
      // 是否显示底部导航
      isShowBottomNav: false,
      audioArr: [
        require("@/assets/music/kaimu.mp3"),
        require("@/assets/music/choujiang.mp3"),
        require("@/assets/music/choujiang.mp3"),
        require("@/assets/music/kaimu.mp3"),
        require("@/assets/music/bimu.mp3")
      ],
      // audio: new Audio(require("@/assets/music/music.mp3")),
      audio: null,
      // audioArr: new Audio(require("@/assets/music/music.mp3")),
      // audio: new Audio(require("@/assets/music/test.mp3")),
      // 是否播放音乐
      isPlayMusic: false,
      // 是否全屏
      isFull: true,
      // 是否显示二维码
      isShowDraggableImg: true,
      // 是否显示弹幕
      isShowDanMu: true,
      // 弹幕的数据
      danmus: [
        { avatar: 'https://www.test.vmaxcloud.com.cn/app/shop/view/public/img/shop_logo.png', name: 'Vmax提醒您', text: '您可以在手机上发起弹幕互动' },
      ],
      activeIndex: 0,
      isLock: true,
      key: '',
      websock: null,
      startHeader: null,
      haderSt: null
    };
  },
  async mounted() {
    let input = document.getElementById('input')
    input.focus()
    // this.navTab(0)
    this.$router.push("/").catch((err) => {
      err;
    });
    this.activeIndex = 0;
    this.videoIndex = 0;
    // await this.$api.sendRequest({
    //   url: '/api/help/info',
    //   params: {
    //     id: 13
    //   }
    // })
    // this.$refs.danmaku.play()
    localStorage.getItem('isLock')
    if (localStorage.getItem('isLock') == 1) {
      this.isLock = false
    }
    this.socketInit()
    window.addEventListener('beforeunload', function (e) {

      var confirmationMessage = '您确定要离开吗？';
      e.returnValue = confirmationMessage;
      if (localStorage.getItem('token')) {
        localStorage.removeItem('token')
        localStorage.setItem('isLock', 4)
      }  // Gecko, Trident, Chrome 34+
      return confirmationMessage;
    });
  },
  destroyed() {
    alert(2)
  },
  methods: {
    /**
     * 鼠标移动控制底部菜单是否显示
     */
    mousemove(e) {
      this.clientHeight = document.documentElement.offsetHeight;
      this.clientWidth = document.documentElement.offsetWidth;

      if (e.clientY > this.clientHeight - 100) {
        this.isShowBottomNav = true;
      } else {
        this.isShowBottomNav = false;
      }
    },
    /**
     * 控制音乐播放
     */
    playMusic() {


      this.isPlayMusic = !this.isPlayMusic;
      if (this.isPlayMusic) {
        this.audio.play();
        this.audio.loop = true;
      } else {
        this.audio.pause();
        this.audio.currentTime = 0;
      }
      // setTimeout(() => {
      //   this.audio.pause();
      // }, 1000)
    },
    /**
     * 底部菜单点击事件
     */
    navTab(e) {
      if (this.websock !== null && (e == 0 || e == 1)) {
        this.websock.close()
        this.socketInit()
      }
      if (this.audio == null) {

      } else if (this.audio.src !== '') {
        let oldAudio = this.audio.src
        let newAudio = new Audio(this.audioArr[e]);
        if (oldAudio == newAudio.src) {

        } else {
          this.audio.pause()
          this.audio.removeAttribute('src')
          this.audio.load()
          this.audio = null
          this.audio = new Audio(this.audioArr[e])
          this.audio.autoplay = true;
          this.audio.loop = true;
          this.isPlayMusic = true
        }
      }

      this.activeIndex = e;
      this.videoIndex = e;
      switch (e) {
        case 0:
          this.$router.push("/").catch((err) => {
            err;
          });
          break;
        case 1:
          this.$router.push("/yaodajiang").catch((err) => {
            err;
          });
          break;
        case 2:
          this.$router.push("/choujiang").catch((err) => {
            err;
          });
          break;
        case 3:
          this.$router
            .push({
              path: "/kaimuAndBimu",
              query: {
                type: 0,
              },
            })
            .catch((err) => {
              err;
            });
          break;
        case 4:
          this.$router
            .push({
              path: "/kaimuAndBimu",
              query: {
                type: 1,
              },
            })
            .catch((err) => {
              err;
            });
          break;
      }
    },
    screenfull() {
      // screenfull.isFullscreen; // 布尔值——当前页面是否全屏
      // screenfull.isEnabled; // 布尔值——当前浏览器是否支持全屏
      // screenfull.request(); // 全屏
      // screenfull.exit(); // 退出全屏
      screenfull.toggle(); // 全屏切换
      this.isFull = screenfull.isFullscreen;
    },
    socketInit() {
      let that = this
      window.clearTimeout(that.haderSt)
      clearTimeout(that.haderSt)
      window.clearTimeout(that.haderSt)
      clearTimeout(that.haderSt)
      if (typeof (WebSocket) === 'undefined') {
        this.$message.error('您的浏览器不支持WebSocket，无法获取数据')
        return false
      }

      this.websock = new WebSocket(this.$config.webSocket)
      this.websock.onmessage = function (e) {
        if (e.data == 'Opened') {
          try {
            that.websock.send('ping')
          } catch (error) {
            console.log('socket报错');
          }
        } else if (e.data == 'Recv: ping') {
          window.clearTimeout(this.startHeader)
          clearTimeout(this.startHeader)
          window.clearTimeout(that.haderSt)
          clearTimeout(that.haderSt)
          that.haderSt = setTimeout(() => {
            try {
              that.websock.send('ping')
            } catch (error) {
              console.log('报错');
            }
          }, 1500)
          return
        } else if (e) {
          console.log('%c [ 数据来喽 ]: ', 'color: #bf2c9f; background: pink; font-size: 13px;', '数据来喽')
        } else {
          that.startHeader = setTimeout(() => {
            try {
              that.websock.close()
            } catch (error) {

            }
            window.clearTimeout(that.haderSt)
            clearTimeout(that.haderSt)
            console.log('断开重连中...');
            that.socketInit()
          }, 3000)
        }



        // if (e.data == 'Recv: ping') {
        //   if (this.startHeader) {
        //     try {
        //       window.clearTimeout(this.startHeader)
        //     } catch (error) {

        //     }
        //     try {
        //       clearTimeout(this.startHeader)
        //     } catch (error) {

        //     }
        //   }

        //   if (this.startHeader) {
        //     try {
        //       window.clearTimeout(that.haderSt)
        //     } catch (error) {

        //     }
        //     try {
        //       clearTimeout(that.haderSt)
        //     } catch (error) {

        //     }
        //   }
        //   that.haderSt = setTimeout(() => {
        //     try {
        //       that.websock.send('ping')
        //     } catch (error) {
        //       console.log('报错');
        //     }
        //   }, 1500)
        //   return
        // } else {
        //   console.log(2222);
        //   that.startHeader = setTimeout(() => {
        //     try {
        //       that.websock.close()
        //     } catch (error) {

        //     }
        //     window.clearTimeout(that.haderSt)
        //     clearTimeout(that.haderSt)
        //     console.log('断开重连中...');
        //     that.socketInit()
        //   }, 2500)
        // }
        let data = e.data !== 'Opened' ? JSON.parse(e.data) : e.data

        // 签到
        if (data !== 'Opened' && data.type == 1) {
          that.$bus.$emit('sign', data)

          // 弹幕
        } else if (data !== 'Opened' && data.type == 2) {
          // if (that.danmus.length >= 100) {
          if (that.danmus.length >= 30) {
            that.$nextTick(() => {
              that.danmus.shift()
            });
          }
          that.$nextTick(() => {
            that.danmus.push({
              avatar: data.avatar_url,
              name: data.nick_name,
              text: data.content
            })
          });
          that.danmus = JSON.parse(JSON.stringify(that.danmus))

          // 抽奖
        } else if (data !== 'Opened' && data.type == 3) {
          that.$bus.$emit('choujiang', data)
        } else if (data !== 'Opened' && data.type == 4) {
          that.$bus.$emit('yaodajiang', data)
        }
        // websocketonmessage(e)
      }
      this.websock.onopen = function () {
        console.log('%c [ onopen ]-209', 'font-size:13px; background:pink; color:#bf2c9f;')
        // try {
        //   that.websock.send('ping')
        // } catch (error) {
        //   console.log('报错open');
        // }
        // websocketOpen()
      }
      this.websock.onerror = function () {
        if (that.startHeader) {
          try {
            window.clearTimeout(that.startHeader)
          } catch (error) {

          }
          try {
            clearTimeout(that.startHeader)
          } catch (error) {

          }
        }

        if (that.startHeader) {
          try {
            window.clearTimeout(that.haderSt)
          } catch (error) {

          }
          try {
            clearTimeout(that.haderSt)
          } catch (error) {

          }
        }
        that.$message.error('ws连接异常，请刷新重试')

        // this.startHeader = setTimeout(() => {
        //   // that.websock.close()
        //   console.log('断开重连中...');
        //   window.clearTimeout(that.haderSt)
        //   clearTimeout(that.haderSt)
        //   that.socketInit()
        // }, 3000)
        // errorCallback()
      }
      this.websock.onclose = function (e) {
        console.log('%c [ onclose ]-217', 'font-size:13px; background:pink; color:#bf2c9f;', e)
        // websocketclose(e)
      }



    },
    async lockey() {
      let res1 = await this.$api.sendRequest({
        url: '/large-screen-login',
        method: 'POST',
        data: {
          password: this.key
        }
      })
      if (res1.data.code >= 0) {
        localStorage.setItem('token', res1.data.data.token)
        localStorage.setItem('isLock', 1)
        this.$message.success('密码正确')

        this.isLock = false

        this.isPlayMusic = true
        this.audio = new Audio(this.audioArr[0])
        this.audio.loop = true
        this.audio.autoplay = true
      } else {
        this.$message.error(res1.data.message)
      }


      // if (this.key !== 'vmax') {
      //   this.$message.error('密码错误')
      // } else {
      //   this.$message.success('密码正确')
      //   this.isLock = false
      // }
    }
  },
};
</script>

<style lang="scss">
.lock {
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.9);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  .centerMinBox {
    .text {
      text-align: center;
      width: 100%;
      font-size: 20px;
      margin-bottom: 10px;
    }

    .inputBox {
      width: 100%;
      height: 100px;
      display: flex;
      justify-content: center;
      align-content: center;
    }

    .lockBtn {
      height: 30px;
      width: 100px;
      margin-left: 10px;
      background-color: #ebf7e4;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #67c23a;
      border-radius: 5px;
      transition: all .3s;
    }

    .lockBtn:hover {
      background-color: #67c23a;
      color: #fff;
    }

    input {
      // 圆角
      border-radius: 5px;
      // 边框
      border: 1px solid #fff;
      // 宽度
      // 字体大小
      font-size: 16px;
      // 字体颜色
      color: #000;
      height: 30px;
      padding-left: 10px;
      box-sizing: border-box;
    }

  }
}

* {
  margin: 0;
  padding: 0;
  user-select: none;
}

.header {
  overflow: hidden;
  position: relative;

  .draggable {
    position: absolute;
    top: 20px;
    left: 20px;
    z-index: 999 !important;
  }

  .actBgc {
    background-color: rgba(255, 255, 255, 0.5) !important;
  }

  .bottomNav {
    position: absolute;
    z-index: 999;
    bottom: 0;
    width: 100vw;
    height: 90px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    user-select: none;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.5);

    .navItem {

      display: flex;
      justify-content: center;
      align-items: center;
      width: 70px;
      height: 70px;
    }



    .icon {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      font-size: 12px;
    }

    .icon>div {
      margin-top: 5px;
    }

    .menuIcon {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      // background-color: rgba(0, 0, 0, 0.9);
    }

    .menu {
      font-size: 17px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 70px;
      height: 70px;

    }

    .line {
      width: 2px;
      height: 50px;
      background-color: #fff;
    }

    .navItem:hover {
      cursor: pointer;
    }

    .menu:hover {
      cursor: pointer;
    }
  }

  .videoMask {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;

    .video {
      width: 100%;
      height: 100%;
      object-fit: cover;
      background-color: #000;
      position: fixed;
      top: 0;
      left: 0;
      z-index: -1;
    }
  }

  .danmaku {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    // background-color: rgba(0, 0, 0, 0.5);
  }

  .danmuBox {
    border-radius: 50px 0 0 50px;
    display: flex;
    align-items: center;
    background-image: url('./assets/images/barbg2.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    // background-position: 25px 0;
    // padding-right: 8px;
    // height: 44px;
    // display: inline-block;
    transition: all .3s;
    height: 40px;
  }

  .avatar {
    position: relative;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 10px;
  }

  .quan {
    position: absolute;
    top: -5px;
    left: -5px;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    animation: quanani 5s linear infinite;
  }

  .danMuimg {
    position: absolute;
    top: 5px;
    left: 5px;
    width: 40px;
    height: 40px;
    background-image: url('https://thirdwx.qlogo.cn/mmopen/vi_32/Q0j4TwGTfTLlztvlBgajZNGs0iamC2Ofq27gccnHBs0UJqzvAbWl7jJ6rlicSia53KmfDcibxbXDanj1ecQ2CMO8oA/132');
    background-size: 100% 100%;
    border-radius: 50%;
    margin-right: 10px;
  }

  .danmuText {
    margin-right: 50px;
    font-size: 20px;
    color: rgb(97, 67, 67);
  }

  @keyframes quanani {

    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(-360deg);
    }
  }
}
</style>
